/* StorePage.module.css */
.root {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
  background: url(https://builder.lashbrookdesigns.com/static/media/custom-mens-wedding-rings.e8a5a924e2a744bc21d6.mp4);
}
.storeVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  z-index: -1;
}
.container {
  @apply flex flex-col h-full;
}
.logo {
  @apply flex-1 h-full flex items-center justify-center;
}
.actions {
  @apply flex sm:flex-col md:flex-col lg:flex-row mb-12 gap-5 px-5;
}
.cta {
  @apply bg-white  p-1;
}
.cta:hover {
  @apply bg-black text-white duration-200 cursor-pointer;
}
.cta .heading {
  @apply border-2 border-gray-950 px-4 py-3 block font-medium;
}
.cta:hover .heading {
  @apply border-2 border-white;
}
.section {
  text-align: center;
  height: 100%;
}

.title {
  font-size: 2rem;
  color: #333;
}

.subtitle {
  font-size: 1.2rem;
  color: #666;
}

.videoBackground {
  background: #000;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -99;
}

.videoForeground,
.videoBackground iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

@media (min-aspect-ratio: 16/9) {
  .videoForeground {
    height: 300%;
    top: -100%;
  }
}

@media (max-aspect-ratio: 16/9) {
  .videoForeground {
    width: 300%;
    left: -100%;
  }
}

.content {
  padding: 25px;
  background-color: rgba(#000, 0.3);
  position: absolute;
  right: 50px;
  bottom: 50px;

  h1 {
    font-family: "Roboto Slab", serif;
    margin: 0;
    color: #fff;
  }
}
